import React, { FC, useRef, useState } from "react"
// @ts-ignore
import logoSrc from "../images/kyrah.png"

// @ts-ignore
import videoSrc from "../images/4Phone_1.mp4"
import styled from "styled-components"
import { motion } from "framer-motion"
import IconButton from "./iconButton"
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa"
import { use100vh } from "react-div-100vh"
import { useBirds } from "../hooks/useBirds"
import useMeasure from "../hooks/useMeasure"
import Bird from "./bird"
import { OrderModal } from "./order-modal"

const Container = styled.div`
  display: grid;
  grid-template-rows: 5fr 1fr;
  max-width: 2200px;
  @media (min-width: 1150px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 2fr;
  }
  @media (min-width: 1350px) {
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 3fr;
  }
  @media (min-width: 1650px) {
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 4fr;
  }
  .content {
    display: grid;
    align-items: space-between;
    justify-content: center;
    padding: 3rem 2rem;
    max-height: 100vh;
    overflow-y: hidden;
    .biography {
      p {
        max-width: 80ch;
        font-size: 1.6rem;
        line-height: 2.7rem;
        color: white;
        border-left: 10px solid #2f2623;
        text-align: justify;
        padding-left: 10px;
        span {
          display: inline;
          background: rgba(47, 38, 35, 0.59);
          color: white;
          padding: 0.005rem;
        }
      }
    }
    .iconRow {
      display: flex;
      a:not(:first-of-type) {
        margin-left: 3rem;
      }
    }
  }
  video {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 35vh;
    @media (min-width: 1150px) {
      order: -1;
      height: 100%;
    }
  }
  .concerts-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 100%;
    .shows {
      border-left: 10px solid #2f2623;
      padding-left: 10px;
      height: min-content;
    }
    h2 {
      font-weight: bolder;
    }
    li {
      list-style: none;
    }
    a,
    a:active,
    a:visited {
      text-decoration: underline;
      color: black;
      font-size: 1.8rem;
    }
    li {
      margin-top: 1.5rem;
    }
  }
`

const generateBirds = (width: number, height: number) => [
  {
    x: `${width * 0.2}px`,
    y: `${height * 0.2}px`,
    scale: Math.max(0.3, Math.random()),
  },
  {
    x: `${width * 0.5}px`,
    y: `${height * 0.2}px`,
    scale: Math.max(0.3, Math.random()),
  },
  {
    x: `${width * 0.3}px`,
    y: `${height * 0.6}px`,
    scale: Math.max(0.3, Math.random()),
  },
  {
    x: `${width * 0.5}px`,
    y: `${height * 0.4}px`,
    scale: Math.max(0.3, Math.random()),
  },
  {
    x: `${width * 0.1}px`,
    y: `${height * 0.1}px`,
    scale: Math.max(0.3, Math.random()),
  },
  {
    x: `${width * 0.8}px`,
    y: `${height * 0.1}px`,
    scale: Math.max(0.3, Math.random()),
  },
  {
    x: `${width * 0.1}px`,
    y: `${height * 0.5}px`,
    scale: Math.max(0.3, Math.random()),
  },
]

const Desktop: FC = () => {
  const height = use100vh()
  const ref = useRef()
  const { width } = useMeasure(ref)
  const { birds, generateNewBirds } = useBirds(width, height, generateBirds)
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <Container style={{ height, maxHeight: height, background: isModalOpen ? "rgba(0,0,0,0.4)" : "" }}>
      {isModalOpen && <OrderModal closeModal={() => setIsModalOpen(false)} />}
      <div ref={ref} className="content">
        {birds.map((b, i) => (
          <Bird key={i} {...b} />
        ))}
        <img src={logoSrc} alt="kyrah logo" />
        <motion.div
          className="biography"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <p>
            <span>
              Fliegen sollte der Mensch können. Dem Boden entfliehen, abheben und durch die Luft gleiten. Dabei die Leichtigkeit
              des Lebens spüren, Risiken wagen und nicht aufgeben. Dieses Gefühl schafft Kyrah durch ihre eingängigen Melodien. In
              ihrer Musik werden die Grenzen des Soul und Pop aufgelöst, was bleibt sind Songs, die ehrlich, direkt und
              eigenständig sind. Jeder Song hat seine eigenen Facetten, die Konstante ist Kyrahs Botschaft: Das Handeln jedes
              Einzelnen hinterlässt seine Spuren auf der Welt und löst Bewegung aus. Diese Botschaft bringt sie nicht nur durch
              ihre wiedererkennbare Stimme sondern auch durch ihre tänzerischen Fähigkeiten zum Ausdruck. Zusammen mit zwei
              Tänzerinnen und einem Schlagzeuger steht sie auf der Bühne und nimmt das Publikum sanft auf eine Reise in andere
              Sphären mit. Genre: Pop
            </span>
          </p>
        </motion.div>
        <div className="concerts-row">
          <iframe
            src="https://open.spotify.com/embed/artist/3vnH0Gedka7ntYMTKOIzXN"
            width="100%"
            height="180"
            frameBorder="0"
            allow-transparency="true"
            allow="encrypted-media"
          ></iframe>
          <div className="shows">
            <h2>Upcoming Shows</h2>
            <ul>
              <li>
                <a href="https://www.seetickets.com/event/underground-sound-presents-the-moustache-bar/the-moustache-bar/2718674">
                  <span>31.08.2023 | 20:00</span>
                  <span> The Moustache Bar</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="iconRow">
          <IconButton target="_blank" href="https://www.facebook.com/kyrahmusicofficial">
            <FaFacebook />
          </IconButton>
          <IconButton target="_blank" href="https://www.instagram.com/kyrah_music/">
            <FaInstagram />
          </IconButton>
          <IconButton target="_blank" href="mailto:kira.stahel@gmail.com">
            <FaEnvelope />
          </IconButton>
        </div>
      </div>
      <video src={videoSrc} muted autoPlay playsInline loop />
    </Container>
  )
}

export default Desktop
