import styled from "styled-components"

const IconButton = styled.a`
  display: block;
  cursor: pointer;
  svg {
    width: 3.6rem;
    height: 3.6rem;
    fill: var(--color-grey);
    transition: all 0.3s;
    &:hover {
      fill: black;
      transform: scale(1.1);
    }
  }
`

export default IconButton
