import React, { FC } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useWindowWidth from "../hooks/useWindowWidth"
import Mobile from "../components/mobile"
import Desktop from "../components/desktop"

const IndexPage: FC = () => {
  const width = useWindowWidth()
  if (typeof window === `undefined` && !width) {
    return <></>
  }

  if (width < 600) {
    return (
      <Layout>
        <SEO title="Kyrah" lang="de" description="Pop Musician from Winterthur" />
        <Mobile />
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title="Kyrah" lang="de" description="Pop Musician from Winterthur" />
      <Desktop />
    </Layout>
  )
}

export default IndexPage
