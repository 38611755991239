import styled from "styled-components"
import useImages from "../hooks/useImages"
import Image from "gatsby-image"
import { use100vh } from "react-div-100vh"
import React from "react"

const Container = styled.div<{ isMobile: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: #dcd4cd;
  padding: 3rem 4rem;
  z-index: 99999999999;
  display: grid;
  row-gap: 2rem;
  overflow-y: auto;
  width: ${props => (props.isMobile ? "100%" : "60rem")};
  height: ${props => (props.isMobile ? "100vh" : "auto")};

  .close {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .description {
    font-size: ${props => (props.isMobile ? "1.5rem" : "2rem")};
    text-align: left;
    line-height: 1.5;
    span {
      margin-bottom: 1rem;
      margin-top: -3rem;
      display: inline-block;
      font-size: ${props => (props.isMobile ? "2.2rem" : "3rem")};
      font-weight: bolder;
      text-transform: uppercase;
    }
  }
  .order {
    display: grid;
    align-items: center;
    justify-content: center;
    a,
    a:link,
    a:visited {
      background: #00191e;
      border-radius: 8px;
      width: max-content;
      padding: 2rem 3rem;
      font-size: ${props => (props.isMobile ? "2rem" : "3rem")};
      box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.27);
      color: #dcd4cd;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      transition: all 300ms ease-in;
      &:hover {
        color: white;
        background: black;
        transform: scale(1.05);
      }
    }
  }
`
export const OrderModal = ({ closeModal, isMobile = false }) => {
  const schoggiImage = useImages(["schoggi.jpg"])[0]
  const height = use100vh()

  return (
    <Container style={{ height: isMobile ? height : "auto" }} isMobile={isMobile}>
      <div className="top">
        <button onClick={closeModal} className="close">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
          </svg>
        </button>
        <p className="description">
          <span>EP Curriculum out soon</span>
          <Image fluid={schoggiImage.fluid} alt="" />
          <br />
          Diese EP wird in Form einer hochwertigen Schokolade der Zürcher Schokoladenmanufaktur LaFlor verschickt. Mit dem Kauf
          unterstützen Sie zusätzlich die Farmer-Gemeinschaft Asoprotesoco, welche sich gegen die Abholzung des Regenwalds und für
          die Aufrechterhaltung der Artenvielfalt in Ecudador einsetzt.
        </p>
      </div>
      <div className="order">
        <a href="https://forms.gle/rTz1tvhMCTTBp7qU9" target="_blank">
          Jetzt Vorbestellen
        </a>
      </div>
    </Container>
  )
}
