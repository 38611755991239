import React, { FC } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

export type IBird  = {
    x: string;
    y: string;
    scale: number;
}

const StyledBird = styled(motion.svg)`
  background: none;
  color: rgba(0, 0, 0, 0.15);
  width: 40rem;
  height: 40rem;
  position: absolute;
`

const Bird: FC<IBird> = ({x, y, scale}) => {
  return (
    <StyledBird
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 125"
      enable-background="new 0 0 100 100"
      initial={{
        opacity: 0,
        scale: 0,
        y: `${Math.random() * 1000}px`,
        x: `${Math.random() * 1000}px`,
        rotate: Math.random() * 100,
      }}
      animate={{ x, y, scale, opacity: 0.3 }}
      transition={{ duration: 1 }}
    >
      <polygon fill="currentColor" points="65.224,29.075 56.42,52.762 83.042,61.147  " />
      <polygon fill="currentColor" points="55.159,54.907 27.8,95.033 56.172,73.146  " />
      <polygon fill="currentColor" points="57.321,72.564 82.544,62.226 56.287,53.957  " />
      <polygon fill="currentColor" points="75.44,45.122 84.033,60.511 88.43,40.525  " />
      <polygon fill="currentColor" points="55.45,51.987 64.376,27.972 15.921,5.233  " />
    </StyledBird>
  )
}

export default Bird;