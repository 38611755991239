import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { use100vh } from "react-div-100vh"
// @ts-ignore
import logoSrc from "../images/kyrah.png"
// @ts-ignore
import videoSrc from "../images/4Phone_1.mp4"
import { FaFacebook, FaInstagram, FaEnvelope, FaArrowLeft } from "react-icons/fa"
import IconButton from "./iconButton"
import Bird, { IBird } from "./bird"
import useWindowWidth from "../hooks/useWindowWidth"
import { useBirds } from "../hooks/useBirds"
import { OrderModal } from "./order-modal"

const Card = styled.div<{ height: number; activeSide: string }>`
  height: ${props => props.height}px;
  perspective: 1000px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  .content {
    position: relative;
    transition: transform 0.8s ease-out;
    transform-style: preserve-3d;
    height: 100%;
    width: 100%;
    transform: ${props => (props.activeSide === "front" ? "rotateY(0)" : "rotateY(180deg)")};
  }
  .front,
  .back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    visibility: visible;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    overflow-y: auto;
  }
  .front {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: ${props => (props.activeSide === "front" ? "all" : "none")};
    img {
      margin-top: 3rem;
      @media screen and (max-width: 350px) {
        transform: scale(0.8);
      }
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: fixed;
      z-index: -1;
    }
    button {
      background: var(--color-primary);
      border: none;
      padding: 1.5rem 3rem;
      letter-spacing: 0.5rem;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
      color: white;
      font-size: 2rem;
      font-family: "Quattrocento Sans", sans-serif;
      border-radius: 5px;
      font-weight: bold;
      display: block;
      width: 100%;
      outline: none;
      &:focus {
        border: 1px solid black;
      }
    }
  }

  .back {
    position: relative;
    padding: 1rem;
    background: var(--color-bg);
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 2.7rem;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100vh;
    @media screen and (max-width: 350px) {
      font-size: 1.3rem;
    }
    @media screen and (max-height: 500px) {
      overflow-y: auto;
    }
    img {
      margin-top: 3rem;
      @media screen and (max-width: 350px) {
        transform: scale(0.8);
      }
    }

    .iconRow {
      display: grid;
      grid-template-columns: 1fr 10fr 1fr;
      z-index: 100;
      width: 100%;
      align-items: center;
      ul {
        border-left: 10px solid #2f2623;
        padding-left: 10px;
      }
      > div {
        justify-self: center;
        display: flex;
        a:not(:first-of-type) {
          margin-left: 3rem;
        }
      }
      button {
        background: none;
        border: none;
      }
    }
  }
  .concerts-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: #a9aeb1;
    padding: 0.5rem;
    h2 {
      font-weight: black;
    }
    li {
      list-style: none;
    }
    a,
    a:active,
    a:visited {
      text-decoration: underline;
      color: black;
      font-size: 1.8rem;
    }
    a {
      display: grid;
      grid-template-rows: 1fr 1fr;
      margin-top: 1rem;
      span:nth-child(2) {
        opacity: 0.6;
      }
    }
  }
`

const generateBirds = (width: number, height: number) => [
  {
    x: `${width * 0.1}px`,
    y: `${height * 0.02}px`,
    scale: Math.max(0.2, Math.random()) * 0.5,
  },
  {
    x: `${width * 0.4}px`,
    y: `${height * 0.1}px`,
    scale: Math.max(0.2, Math.random()) * 0.5,
  },
  {
    x: `${width * 0.7}px`,
    y: `${height * 0.3}px`,
    scale: Math.max(0.2, Math.random()) * 0.5,
  },
  {
    x: `${width * 0.4}px`,
    y: `${height * 0.6}px`,
    scale: Math.max(0.2, Math.random()) * 0.5,
  },
  {
    x: `${width * 0.1}px`,
    y: `${height * 0.4}px`,
    scale: Math.max(0.2, Math.random()) * 0.5,
  },
  {
    x: `${width * 0.05}px`,
    y: `${height * 0.5}px`,
    scale: Math.max(0.2, Math.random()) * 0.5,
  },
  {
    x: `${width * 0.6}px`,
    y: `${height * 0.6}px`,
    scale: Math.max(0.2, Math.random()) * 0.5,
  },
]

const Mobile: FC = () => {
  const width = useWindowWidth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const height = use100vh()
  const [activeSide, setActiveSide] = useState<"front" | "back">("front")
  const toggleActiveSide = () => {
    setActiveSide(activeSide === "front" ? "back" : "front")
    typeof window !== "undefined" && setTimeout(generateNewBirds, 350)
  }
  const { birds, generateNewBirds } = useBirds(width, height, generateBirds)
  return (
    <Card height={height} activeSide={activeSide}>
      {isModalOpen && <OrderModal isMobile={false} closeModal={() => setIsModalOpen(false)} />}
      <div className="content">
        <AnimatePresence>
          {activeSide === "front" && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.2 }}
              className="front"
            >
              <img src={logoSrc} alt="kyrah logo" />
              <video src={videoSrc} muted autoPlay playsInline loop />
              <button onClick={toggleActiveSide}>Info</button>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="back">
          {birds.map((b, i) => (
            <Bird key={i} {...b} />
          ))}
          <img src={logoSrc} alt="kyrah logo" />
          <p>
            Fliegen sollte der Mensch können. Dem Boden entfliehen, abheben und durch die Luft gleiten. Dabei die Leichtigkeit des
            Lebens spüren, Risiken wagen und nicht aufgeben. Diese Botschaft bringt Kyrah nicht nur durch ihre wiedererkennbare
            Stimme sondern auch durch ihre tänzerischen Fähigkeiten zum Ausdruck. Zusammen mit zwei Tänzerinnen und einem
            Schlagzeuger steht sie auf der Bühne und nimmt das Publikum sanft auf eine Reise in andere Sphären mit. <br /> <br />
            Genre: Pop
          </p>
          <div className="concerts-row">
            <iframe
              src="https://open.spotify.com/embed/artist/3vnH0Gedka7ntYMTKOIzXN"
              width="100%"
              height="80"
              frameBorder="0"
              allow-transparency="true"
              allow="encrypted-media"
            ></iframe>

            <div>
              <h2>Upcoming Shows</h2>
              <ul>
                <li>
                  <a href="https://www.seetickets.com/event/underground-sound-presents-the-moustache-bar/the-moustache-bar/2718674">
                    <span>31.08.2023 | 20:00</span>
                    <span> The Moustache Bar</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="iconRow">
            <IconButton as="button" onClick={toggleActiveSide}>
              <FaArrowLeft />
            </IconButton>
            <div>
              <IconButton target="_blank" href="https://www.facebook.com/kyrahmusicofficial">
                <FaFacebook />
              </IconButton>
              <IconButton target="_blank" href="https://www.instagram.com/kyrah_music/">
                <FaInstagram />
              </IconButton>
              <IconButton target="_blank" href="mailto:kira.stahel@gmail.com">
                <FaEnvelope />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Mobile
