import { graphql, useStaticQuery } from "gatsby"

const ALL_IMAGES = graphql`
  query AllImages {
    images: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
      edges {
        node {
          extension
          relativePath
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export type ImageSharpFluid = {
  aspectRatio: number
  base64: string
  sizes: string
  src: string
  srcSet: string
  srcSetWebp: string
  srcWebp: string
}

/**
 *
 * @param relativePaths
 * Returns all images that match one of the relative paths provided as an array.
 */

function useImages(relativePaths: string[]): { fluid: ImageSharpFluid; relativePath: string }[] {
  const data = useStaticQuery(ALL_IMAGES)
  return data.images.edges
    .filter((img: any) => relativePaths.includes(img.node.relativePath))
    .map((img: any) => ({ fluid: img.node.childImageSharp.fluid, relativePath: img.node.relativePath }))
}

export default useImages
