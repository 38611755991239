import { useEffect, useState } from "react"
import { IBird } from "../components/bird"

export function useBirds(width: number, height: number, generateBirds: (width: number, height: number) => IBird[]) {
  const [birds, setBirds] = useState<IBird[]>(() => generateBirds(width, height))

  useEffect(() => {
    setBirds(generateBirds(width, height))
  }, [width, height])

  const generateNewBirds = () => {
    setBirds(generateBirds(width, height))
  }

  return {birds, generateNewBirds}
}
